@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.skin5 {
  height: 100%;
  background-color: #f5f6f9;
  line-height: normal;

  .header {
    padding: 20px 16px 10px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 220px;
    background-image: url("~@imgs/register/new-theme/head-bg.png");

    .logoBox {
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      display: flex;
      align-items: center;

      .logo {
        margin-right: 10px;
        width: 42px;
        height: 42px;
        background-size: 100%;
        background-image: url("~@imgs/register/red-theme/logo-big.png");
      }

      .logoBoxRight {
        display: flex;
        flex-direction: column;
        .logoText {
          width: 80px;
          height: 21px
        }
        .youqianLogoText {
          //width: 54px;
          height: 21px
        }
        .logoText2 {
          margin-top: 4px;
          width: 76px;
          height: 10px
        }
        .logoText3 {
          margin-top: 1px;
          font-size: 12px;
          color: #fff;
          font-weight: 600;
          transform: scale(.86) translateX(-10px);
        }
      }
    }

    .inputWrapper {
      margin-top: 25px;
      width: 343px;
      background: linear-gradient(180deg, #FBE5D7 0%, #FFFFFF 27.54%);
      border-radius: 8px;

      .inputWrapperHead {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 29px;
      }

      .inputWrapperContainer {
        margin-top: 12px;
        padding: 24px 12px 16px;
        .inputWrapperContainetTips {
          font-size: 14px;
          margin-bottom: 8px;
          color: #262626;
        }
        .inputBox {
          position: relative;
          display: flex;
          align-items: center;
          .inputBoxSymbol {
            font-size: 38px;
            line-height: 52px;
          }
    
          :global {
            .adm-input-element {
              font-size: 33px;
              font-weight: 600;
              line-height: 30px;
              &::placeholder {
                font-size: 22px;
                line-height: 30px;
                font-weight: normal;
              }
            }
          }
    
          .text {
            position: absolute;
            right: 0;
            bottom: 14px;
            display: flex;
            align-items: center;
            color: #AD6800;
            font-size: 12px;
    
            .clear {
              padding-left: 9px;
              color: #8C8C8C;
              font-size: 16px;
            }
          }
        }
        .moneyOptions {
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .moneyItem {
            flex: 1;
            height: 32px;
            padding: 4px 8px;
            border-radius: 6px;
            box-sizing: border-box;
            font-size: 14px;
            background-color: #F3F4F6;
            color: #8C8C8C;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: normal;
            font-weight: 600;
            &:nth-child(2n + 2) {
              margin: 0 8px;
            }
            &.moneyItemActive {
              color: #ff2f2f;
              border: 1px solid #ff2f2f;
              background-color: rgba(255, 44, 62, 0.10);
            }
          }
        }
        .line {
          margin: 16px 0;
          width: 100%;
          height: 1px;
          background-color: #EAEAEA;
        }
        .moneyRateTips {
          display: flex;
          align-items: center;
          .moneyRateText1 {
            padding: 3px 8px;
            background-color: #DE9960;
            border-radius: 4px;
            color: #fff;
            font-size: 12px;
            margin-right: 8px;
          }
          .moneyRateText2 {
            color: #262626;
            font-size: 12px;
          }
        }
      }
    }
  }

  .checkBox {
    width: 352px;
    margin: 11px auto 16px;
    padding-bottom: 32px;
    background: #fff;
    border-radius: 8px;
    .title {
      width: 100%;
      height: 38px;
      background: linear-gradient(180deg, #FDF2E5 0%, #FBE4CE 100%);
      border-radius: 6px 6px 0px 0px;
      text-align: center;
      line-height: 38px;
      font-weight: 400;
      font-size: 12px;
      color: #DE9960;
    }
    h3 {
      font-weight: 600;
      font-size: 16px;
      color: #333;
      padding: 12px 16px 0;
      span {
        font-size: 12px;
        color: #BB6C1D;
        font-weight: normal;
      }
    }
    .loanTermList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 16px;
      margin-top: 16px;
      .loanTermItem {
        width: 101px;
        height: 30px;
        background-color: #f3f4f6;
        text-align: center;
        border-radius: 5px;
        box-sizing: border-box;
        font-weight: 600;
        p {
          font-size: 14px;
          color: #8C8C8C;
          &.samllText {
            font-size: 12px;
          }
        }
        &.active {
          background-color: #FF2C3E;
          p {
            font-size: 14px;
            color: #fff;
          }
        }
        &.is360Channel {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
        }
      }
    }

    .bottomBody {
      margin-top: 20px;
      box-sizing: border-box;
      padding: 0 16px;
      p {
        width: 100%;
        display: flex;
        justify-content: center;
        span {
          display: inline-block;
          color: #c16818;
          height: 41px;
          line-height: 41px;
          padding: 0 16px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("~@imgs/register/new-theme/bottom-body-bg.png");
        }
      }
      .phoneBox {
        height: 46px;
        background: #f4f5f7;
        border-radius: 8px;
        display: flex;
        align-items: center;
        margin: 9px auto 0;
        padding-left: 16px;
        .label {
          width: 170px;
          text-align: center;
          font-size: 16px;
        }
        :global {
          .adm-input-element {
            font-size: 16px;
            line-height: 30px;
            &::placeholder {
              font-size: 16px;
              line-height: 30px;
            }
          }
        }
      }
      .getCms {
        margin-top: 12px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(180deg,#ffcfa0 1%,#e19459);
        border-radius: 6px;
        color: #FFFFFF;
        font-size: 16px;
      }
      .agreement {
        @include flex-cc;
        flex-wrap: wrap;

        .primary {
          color: #DE9960;
        }
    
        margin-top: 20px;
        font-size: 12px;
        color: #8C8C8C;
        line-height: 18px;

        &.animation {
          position: relative;
          animation: shake .1s;
        }

        @keyframes shake {
          0% {
            left: 8px;
          }

          50% {
            left: -8px;
          }
    
          100% {
            left: 0;
          }
        }
    
        .checked {
          margin-right: 6px;
          border: 1px solid #D9D9D9;
          border-radius: 100%;
          width: 18px;
          height: 18px;
    
          &.active {
            position: relative;
            border: none;
            background: #DE9960;
    
            &::before {
              position: absolute;
              top: 6px;
              left: 4px;
              width: 10px;
              height: 7px;
              content: "";
              background-image: url("~@imgs/activity/check.png");
              background-size: 100%;
            }
          }
        }
      }
    }
  }

  .tipBox {
    padding-bottom: 50px;
    .tip {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: $text-gray-color;
    }
  }

  .dialogOverlay {
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    &.showPopup {
      display: block;
    }
    .dialogMain {
      width: 320px;
      height: 180px;
      border-radius: 8px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px 0;
      box-sizing: border-box;
      .close {
        width: 100%;
        font-size: 20px;
        text-align: right;
        margin-right: 20px;
        color: rgb(214, 217, 223);
        padding-right: 20px;
        box-sizing: border-box;
      }
      .inputBox {
        width: 285px;
        height: 48px;
        border: 1px solid #e19459;
        margin: 10px auto;
        padding-left: 20px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        .code {
          width: 150px;
          text-align: center;
          color: #e19459;
        }
        :global {
          .adm-input-element {
            font-size: 15px;
            line-height: 30px;
          }
        }
      }
      .getQuota {
        margin: 18px auto;
        width: 283px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(180deg,#ffcfa0 1%,#e19459);
        border-radius: 6px;
        color: #FFFFFF;
        font-size: 16px;
      }
    }
  }
}

:global {
  .adm-auto-center {
    .adm-auto-center-content {
      text-align: center !important;
    }
  }
}
