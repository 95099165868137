@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.auditNew {
  padding: 16px;
  background-color: #F5F5F5;

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .content {
    .row {
      margin-top: 12px;
      border-radius: 8px;
      padding: 12px;
      background-color: $color-white;

      @include flex-vc;

      .left {
        margin-right: 12px;
        border-radius: 4px;
        width: 130px;
        height: 78px;
        background-color: #CCCCCC;
        object-fit: cover;
        flex: none;

        .img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .right {
        flex: 1;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: 78px;

        .name {
          font-weight: bold;
          line-height: 20px;

          @include ellipsis-basic(2);
        }

        .desc {
          color: #8C8C8C;

          @include ellipsis-basic(2);
        }
      }
    }
  }
}
