@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  border-bottom: 1px solid $border-color;
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: $color-black;
  background-color: $color-white;
  line-height: 48px;

  .back {
    position: absolute;
    top: 50%;
    left: 0;
    width: 48px;
    height: 48px;
    transform: translateY(-50%);
  }
}
