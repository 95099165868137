@import "@/styles/var.scss";
@import "@/styles/mixin.scss";
@import "@/styles/skin12.scss";

.iosSkin {
  height: 100%;
  line-height: normal;
  background-color: #f5f6f9;

  .main {
    padding: 20px 16px 10px;
    background-repeat: no-repeat;
    background-size: 100% 432px;
    background-image: url("~@imgs/ios-skin/head-bg.png");

    .logoBox {
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;

      @include flex-cc;

      .logo {
        margin-right: 10px;
        width: 49px;
        height: 49px;
        background-size: 100%;
        background-image: url("~@imgs/register/red-theme/logo-big.png");
      }

      .logoBoxRight {
        display: flex;
        flex-direction: column;
        .logoText {
          width: 80px;
          height: 21px;
        }
        .youqianLogoText {
          //width: 54px;
          height: 21px;
        }
        .logoText2 {
          margin-top: 4px;
          width: 79px;
          height: 11px;
        }
        .logoText3 {
          margin-top: 1px;
          font-size: 12px;
          color: #fff;
          font-weight: 600;
          transform: scale(0.86) translateX(-10px);
        }
      }
    }

    .applyResultBox {
      margin-top: 15px;
      background: linear-gradient(314deg, #fffaf9 0%, #ffeee6 100%);
      border-radius: 7px;
      border: 2px solid #dd2231;
      display: flex;
      align-items: center;
      padding: 9px;
      .applyResultBoxIcon {
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        margin-right: 8px;
      }
      .applyResultBoxRight {
        .applyResultBoxText1 {
          font-size: 16px;
          font-weight: bold;
          color: #000;
          .applyPrductionName {
            color: #f80109;
          }
        }
        .applyResultBoxText2 {
          margin-top: 4px;
          font-size: 14px;
          color: #967569;
          .highlight {
            color: #f80109;
          }
        }
      }
    }

    .containers {
      margin-top: 13px;
      border-radius: 14px;
      background-color: #fff;
      padding: 36px;
      &.passBox {
        height: 460px;
      }
      &.failBox {
        height: 550px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .noPass {
          width: 72px;
          height: 72px;
        }
        .failBoxText1 {
          margin-top: 16px;
          font-size: 21px;
          font-weight: bold;
          color: #ff041d;
        }
        .failBoxText2 {
          margin-top: 25px;
          font-size: 16px;
          color: #555555;
        }
        .failBoxText3 {
          margin-top: 4px;
          font-size: 13px;
          color: #9c9c9c;
        }
      }
      .line {
        width: 1px;
        height: 35px;
        margin-left: 7px;
      }
      .containerItem {
        display: flex;
        align-items: center;
        .applyResultBoxIcon {
          width: 17px;
          height: 17px;
          margin-right: 24px;
        }
        .containerItemSuccessText {
          color: #ff2e43;
          font-size: 18px;
          font-weight: bold;
        }
        .containerItemFailText {
          color: #939393;
          font-size: 18px;
        }
      }
    }
  }
}
