@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.registerAgreement {
  overflow: auto;
  padding: 16px;
  height: 100%;
  font-size: 12px;
  color: $color-black;
  background-color: $color-white;

  &.isShowHeader {
    padding-top: 64px;
  }

  .title {
    margin-bottom: 10px;
    font-size: 18px;
    text-align: center;
  }

  .subTitle {
    margin: 8px 0;
    font-size: 14px;
  }

  p {
    text-align: justify;
    text-indent: 2em;
    line-height: 20px;
  }

  .bold {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
  }

  .emptyLine {
    height: 20px;
  }

  .btn {
    margin: 40px auto 0;
    border: none;
    padding: 0;
    width: 200px;
    height: 48px;
    font-size: 16px;
    font-weight: bold;
    background: $linear-gradient;
    line-height: 48px;
    position: fixed;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
}
