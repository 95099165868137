.iosSkin.skin12, .fullPage.skin12{
  .main {
    background-image: $linear-gradient-skin12;
    .applyResultBox {
      border-color: $text-skin12;
      .applyResultBoxRight {
        .applyResultBoxText1 {
          .applyPrductionName {
            color: $text-skin12;
          }
        }
        .applyResultBoxText2 {
          .highlight {
            color: $text-skin12;
          }
        }
      }
    }
    .containers {
      .card {
        .apply {
          background: $linear-gradient-skin12;
        }
      }
      &.failBox {
        .failBoxText1 {
          color: $text-skin12;
        }
      }
      .containerItem {
        .containerItemSuccessText {
          color: $text-skin12;
        }
      }
    }
  }
  .btn {
    background: $linear-gradient-skin12 !important;
  }
}

.isSkin12.chooseOption {
  .optionBox {
    .item {
      &.active {
        background: $linear-gradient-skin12;
      }
    }
  }
}


.isSkin12.formChooseItem {
  .options {
    .option {
      &.active {
        background: $linear-gradient-skin12 !important;
      }
    }
  }
}
