// 公共颜色
$color-primary: #FF4D4F;

// 主字色
$color-black: #262626;
$color-white: #FFFFFF;

// 浅红
$light-red: #FFF1F0;

// 浅红背景
$light-red-bg: #FFF3F4;

// 背景色
$background-color: #F6F6F6;

// 线条颜色
$line-color: #F0F0F0;

// 边框颜色
$border-color: #D9D9D9;

// 文字灰色颜色
$text-gray-color: #8C8C8C;

// 渐变色
$linear-gradient: linear-gradient(108.61deg, #FF606E 3.46%, #FF192C 97.1%);

// skin12字体颜色
$text-skin12: #684aff;

// skin12渐变色
$linear-gradient-skin12: linear-gradient(1turn, #a18eff, #684aff);

// 占位符颜色
$placeholder-color: #BFBFBF;
