@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.modalPage {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);

  .main {
    position: absolute;
    top: 33%;
    left: 50%;
    transform: translateX(-50%) translateY((-50%));
    width: 320px;
    border-radius: 8px;
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
    .modalTitle {
      color: #262626;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }
    .modalText1 {
      margin: 20px 0 15px;
      color: #50515C;
      text-align: center;
      font-size: 15px;
    }
    .protocolIds {
      .protocolIdsItem {
        margin: 0 auto 8px;
        width: 238px;
        height: 44px;
        border-radius: 8px;
        background: linear-gradient(180deg, #FFF5F6 0%, rgba(255, 245, 246, 0.00) 100%);
        text-align: center;
        line-height: 44px;
        color: #E14E5B;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .btnsBox {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      .cancelBtn {
        width: 120px;
        border: none;
        padding: 0;
        height: 48px;
        line-height: 50px;
        margin-right: 10px;
        border: 1px solid #E14E5B;
        background: #FFEBED;
        color: #E14E5B;
      }
      .btn {
        width: 120px;
        border: none;
        padding: 0;
        height: 48px;
        background: $linear-gradient;
        line-height: 50px;
        margin-right: 10px;
      }
    }
  }
}
