@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.modalPage {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 50%);

  .main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY((-50%));
    width: 300px;

    .content {
      border-radius: 10px;
      padding: 40px 20px 20px;
      width: 300px;
      text-align: center;
      background-color: $color-white;
      box-shadow: 0 9px 28px 8px rgb(0 0 0 / 5%), 0 6px 16px rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%);

      .img {
        width: 107px;
        height: 87px;
        margin-bottom: 2px;
      }

      .title {
        // margin-bottom: 20px;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        color: #181830;
      }

      .btn {
        border: none;
        padding: 0;
        height: 48px;
        background: $linear-gradient;
        line-height: 50px;
      }
    }

    .close {
      margin: 20px auto 0;
      width: 28px;
      height: 28px;
      background-size: 100% 100%;
      background-image: url("~@imgs/information/close.png");
    }
  }

  .phoneBox {
    height: 50px;
    background: #f4f5f7;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin: 9px auto 0;
    .label {
      width: 170px;
      text-align: center;
      font-size: 16px;
    }
    :global {
      .adm-input-element {
        font-size: 16px;
        // font-weight: 600;
        line-height: 30px;
        &::placeholder {
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
  }
}

.formBox {
  margin-top: 2px;
  margin-bottom: 20px;
  .inputBox {
    position: relative;
    margin-top: 12px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding-left: 16px;
    height: 44px;
    background: $color-white;
    line-height: 44px;
  
    :global {
      .adm-input-element {
        font-size: 14px;
        line-height: 44px;
      }
    }
  
    .code {
      position: absolute;
      top: 50%;
      right: 16px;
      font-size: 14px;
      // font-weight: 600;
      color: $color-primary;
      transform: translateY(-50%);
    }
  
    .codeText {
      opacity: .5;
    }
  }
  
  :global {
    .adm-list-item,
    .adm-list-item-content,
    .adm-list-item-content-main {
      padding: 0 !important;
    }
  
    .adm-list-default .adm-list-body {
      border: none;
    }
  
    .adm-list-item-content {
      border: none;
    }
  
    .adm-list-body,
    .adm-list-item {
      background-color: transparent;
    }
  
    .adm-form-item-feedback-error {
      padding-left: 16px;
      text-align: left;
    }
  }
}

// .title {
//   font-size: 12px;
//   color: #545455;
// }

// .formBox {
//   position: relative;
//   margin-top: 16px;
//   border-radius: 12px;
//   padding: 8px;
//   background-color: $color-white;
//   box-shadow: 0 4px 20px rgb(194 194 194 / 20%);

//   .mask {
//     position: relative;
//     box-sizing: border-box;
//     padding: 14px;
//     text-align: center;

//     &::before {
//       position: absolute;
//       top: 0;
//       left: 0;
//       border: 1px solid #EBEEF2;
//       border-radius: 8px;
//       width: 100%;
//       height: 100%;
//       content: "";
//       box-sizing: border-box;
//     }

//     &::after {
//       position: absolute;
//       top: 0;
//       left: 0;
//       z-index: 1;
//       width: 100%;
//       height: 100%;
//       content: "";
//       background:
//         linear-gradient(
//           180deg,
//           transparent 0%,
//           #FFFFFF 100%
//         );
//     }

//     .topText {
//       position: absolute;
//       top: 0;
//       left: 50%;
//       z-index: 2;
//       width: 140px;
//       height: 20px;
//       font-size: 12px;
//       font-family: "PingFang SC", Helvetica, Arial, Verdana, Tahoma, sans-serif;
//       color: $color-white;
//       background-size: 100%;
//       transform: translateX(-50%);
//       background-image: url("~@imgs/register/red-theme/text-bg.png");
//     }

//     .textTip {
//       margin-top: 20px;
//       font-size: 14px;
//       color: $text-gray-color;
//       opacity: .8;
//     }

//     .desc {
//       margin: 10px 0 0;
//       opacity: .8;

//       .orange {
//         color: #FF8739;
//       }
//     }

//     .amount {
//       font-size: 48px;
//       font-weight: 600;
//       line-height: 68px;
//     }

//     .percentage {
//       margin: 16px auto 20px;
//       width: calc(94px * 2);
//       height: calc((31px * 2));
//       background-size: 100% 100%;
//       background-image: url("~@imgs/register/red-theme/80percentage-big.png");
//     }

//     .form {
//       position: relative;
//       z-index: 2;
//     }

//     :global {
//       .adm-list-item,
//       .adm-list-item-content,
//       .adm-list-item-content-main {
//         padding: 0 !important;
//       }

//       .adm-list-default .adm-list-body {
//         border: none;
//       }

//       .adm-list-item-content {
//         border: none;
//       }

//       .adm-list-body,
//       .adm-list-item {
//         background-color: transparent;
//       }

//       .adm-form-item-feedback-error {
//         padding-left: 16px;
//         text-align: left;
//       }
//     }

//     .title {
//       font-size: 12px;
//       color: #545455;
//     }

//     .inputBox {
//       position: relative;
//       margin-top: 12px;
//       border: 1px solid #D9D9D9;
//       border-radius: 23px;
//       padding-left: 16px;
//       height: 46px;
//       background: $color-white;
//       line-height: 46px;

//       :global {
//         .adm-input-element {
//           font-size: 16px;
//           line-height: 46px;
//         }
//       }

//       .code {
//         position: absolute;
//         top: 50%;
//         right: 16px;
//         font-size: 16px;
//         font-weight: 600;
//         color: $color-primary;
//         transform: translateY(-50%);
//       }

//       .codeText {
//         opacity: .5;
//       }
//     }

//     .agreement {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       margin-top: 12px;
//       font-size: 12px;
//       color: #40404E;

//       .checked {
//         margin-right: 6px;
//         border: 1px solid #D9D9D9;
//         border-radius: 100%;
//         width: 18px;
//         height: 18px;

//         &.active {
//           position: relative;
//           border: none;
//           background: $linear-gradient;

//           &::before {
//             position: absolute;
//             top: 6px;
//             left: 4px;
//             width: 10px;
//             height: 7px;
//             content: "";
//             background-image: url("~@imgs/activity/check.png");
//             background-size: 100%;
//           }
//         }
//       }
//     }

//     .register {
//       margin-top: 16px;
//       border-radius: 24px;
//       height: 48px;
//       font-size: 16px;
//       font-weight: bold;
//       text-align: center;
//       color: $color-white;
//       background: $linear-gradient;
//       line-height: 48px;
//     }

//     .step {
//       @include flex-sbc;

//       margin-top: 32px;
//       border-radius: 8px;
//       padding: 4px 14px;
//       width: 302px;
//       font-size: 10px;
//       color: $color-primary;
//       background-color: $light-red;
//       line-height: 14px;

//       .item {
//         display: flex;
//         align-items: center;
//       }

//       .circle {
//         @include flex-cc;

//         margin-right: 4px;
//         border: 1px solid $color-primary;
//         border-radius: 50%;
//         width: 12px;
//         height: 12px;
//         font-size: 8px;
//       }

//       .arrow {
//         position: relative;

//         &::after {
//           position: absolute;
//           top: 0;
//           right: 0;
//           border: 1px solid $color-primary;
//           border-color: $color-primary $color-primary transparent transparent;
//           width: 6px;
//           height: 6px;
//           content: "";
//           transform: translateY(-50%) rotate(45deg);
//         }

//         &::before {
//           position: absolute;
//           top: 50%;
//           right: -1px;
//           content: "";
//           transform: translateY(-50%);
//           width: 8px;
//           height: 1px;
//           background-color: $color-primary;
//         }
//       }
//     }
//   }
// }
