@import "@/styles/var.scss";
@import "@/styles/mixin.scss";
@import "@/styles/skin12.scss";

.chooseOption {
  &:first-child {
    .top {
      border-top: none;
    }
  }

  &.borderBottom {
    .top {
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 16px;
    }
    .optionBox {
      .item {
        background-color: #fff;
      }
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $line-color;
    padding: 12px 0;
    height: 48px;
    font-size: 14px;

    .subName {
      font-weight: normal;
      color: #a4a4a4;
      font-size: 12px;
    }

    .placeholder {
      color: $placeholder-color;
    }

    .right {
      padding-right: 3px;
    }

    .arrow {
      position: relative;
      top: 0;
      display: inline-block;
      margin-left: 7px;
      border: 1px solid $placeholder-color;
      border-color: transparent $placeholder-color $placeholder-color
        transparent;
      width: 9px;
      height: 9px;
      transition: all 0.3s;
      transform: translateY(-50%) rotate(45deg);

      &.rotate {
        position: relative;
        top: 7px;
        transform: translateY(-50%) rotate(225deg);
      }
    }
  }

  .optionBox {
    display: flex;
    overflow: hidden;
    margin-right: -8px;
    height: 0;
    flex-wrap: wrap;
    transition: height 0.3s;

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 7px;
      margin-bottom: 8px;
      border-radius: 18px;
      min-width: 98px;
      height: 36px;
      font-size: 14px;
      text-align: center;
      background-color: $background-color;

      &.active {
        font-weight: bold;
        color: $color-white;
        background: $linear-gradient;
      }
    }
  }
}
