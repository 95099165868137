@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.form {
  position: relative;
  .userInfoItem {
    padding: 0 20px;
    background-color: #fff;
    border-radius: 8px 8px 8px 8px;
    .flex {
      display: flex;
      align-items: center;
    }
    .arrow{
      height: 14px;
      width: 8px;
    }
    .assetInfomation{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 58px;
      .walletIcon{
        height: 16px;
        width: 16px;
        padding-right: 8px;
      }
      .assetNum{
        font-size: 16px;
        line-height: 18px;
        color: #4E191E;
      }
    }
    .border {
      border-bottom: 1px solid #F2F2F2;
    }
    .formList{
      .formItem{
        .formItemTitle{
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .leftItemBox{
            font-size: 16px;
            color: #333333;
            line-height: 18px;
          }
          .placeChoose{
            padding-right: 12px;
            font-size: 14px;
            color: #BFBFBF;
          }
          .chooseLabel {
            font-size: 16px;
            color: #666666;
            padding-right: 10px;
          }
        }
        .formItemContent{
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          .formItem{
            width: 143px;
            height: 39px;
            background: #F2F3F5;
            border-radius: 2px 2px 2px 2px;
            font-size: 15px;
            line-height: 39px;
            text-align: center;
            color: #262626;
            margin-bottom: 10px;
          }
          .activeItem {
            background: linear-gradient( 135deg, #FF7A86 0%, #FF1C2F 100%) !important;
            color: #FFFFFF !important;
          }
        }
      }
    }
    .icon {
      height: 14px;
      width: 14px;
      padding-right: 12px;
      padding-bottom: 8px;
    }
  }
  .btn {
    position: fixed;
    left: 16px;
    bottom: 32px;
    margin: 0 auto;
    border: none;
    padding: 0;
    width: 343px;
    height: 48px;
    font-size: 16px;
    font-weight: bold;
    background: $linear-gradient;
    line-height: 48px;
  }
  .lastBoxItem {
    margin-bottom: 8px;
    .formBox {
      margin-top: 8px;
      padding: 0 12px;
      background-color: #ffffff;
      border-radius: 8px;
    }
  }
  :global {
    .adm-input-element {
      font-size: 15px;
      line-height: 1;
      color: #262626;
      background-color: #ffffff;
    }
    
  
    .adm-list-body {
      background-color: transparent;
    }
  
    .adm-list-card {
      margin: 0;
    }
  
    .adm-list-item {
      padding: 0;
    }
  
    .adm-list-item-content {
      height: 60px;
      padding-right: 0;
      background-color: #ffffff;
      border: none;
    }
  
    .adm-form-item-label .adm-form-item-required-asterisk {
      display: none;
    }
  
    input:-webkit-autofill {
      box-shadow: 0 0 0 1000px #ffffff inset;
    }
  
    .adm-form-item-label {
      font-size: 15px;
      width: 90px;
      line-height: 2;
      color: #333333;
      background-color: transparent;
    }
    .adm-list-item-content-prefix {
      @include flex-vc;
    }
  
    .adm-list-item-content-arrow {
      // transform: rotate(90deg);
      font-size: 14px;
      padding-bottom: 8px;
    }
  
    .adm-form-item-feedback-error {
      text-align: left;
    }
    .adm-list-default .adm-list-body {
      border: none;
    }
  }
}
.btnList{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 0 20px;
}
.noticle{
  font-size: 12px;
  background: #FFF7F5;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0 16px;
  color: #FC5555;
}

