@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.contact {
  .main {
    text-align: center;

    .title.ignoreConvert {
      font-size: 40px;
      font-weight: bold;
      line-height: normal;
    }

    .ignoreConvert {
      line-height: normal;
      padding: 4px 0;
    }
  }
}
