@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.successPage {
  padding-bottom: 100px;
  .apiToH5Skin {
    line-height: normal;
    background: linear-gradient(360deg, rgba(255,236,213,0) 0%, #FF1C2F 100%) no-repeat;
    background-size:  581px 581px;
    padding: 16px 0;
    .apiToH5Skin_body {
      padding: 0 16px;
      .cardBox {
        width: 100%;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
        .cardBoxHeader {
          height: 40px;
          display: flex;
          align-items: center;
          position: relative;
          .cardBoxHeaderBg {
            width: 100%;
            height: 40rpx;
            position: absolute;
            left: 0;
            top: 0;
          }
          .cardBoxHeaderLeft {
            flex: 1;
            padding-left: 12px;
            position: relative;
            font-size: 15px;
            color: #6E1D12;
            font-weight: bold;
          }
          .cardBoxHeaderRight {
            flex: 1;
            padding-left: 12px;
            position: relative;
            display: flex;
            align-items: center;
            margin-top: 4px;
            .cardBoxHeaderRightIcon {
              width: 15px;
              height: 8px;
            }
            .cardBoxHeaderRightText {
              margin-left: 8px;
              font-size: 13px;
              color: #B3781A;
            }
          }
        }
        .cardBoxContent {
          padding: 12px 0;
          .companyBox {
            padding: 0 28px;
            display: flex;
            align-items: center;
            .companyBoxLogo {
              width: 35px;
              height: 35px;
            }
            .companyBoxRight {
              flex: 1;
              margin-left: 8px;
              .companyBoxProductName {
                font-size: 16px;
                font-weight: bold;
                color: #000;
              }
              .companyBoxName {
                margin-top: 2px;
                font-size: 12px;
                color: #50515C;
              }
            }
          }
          // 产品列表
          .productBox {
            .title {
              padding: 11px 0;
              margin: 0 20px 0 28px;
              font-size: 15px;
              font-weight: 600;
              color: #000;
              border-bottom: 1px solid #EFEFEF;
              .titleTextActive {
                color: #FF3042;
              }
            }

            .productListBox {
              padding: 13px 13px;
              .productItemBox {
                margin-bottom: 8px;
                padding: 11px;
                border-radius: 3px;
                // border: 1px solid #FF7380;
                background: #F5F6F8;
                position: relative;
                display: flex;
                align-items: center;
                .productItemIcon {
                  width: 49px;
                  height: 52px;
                  object-fit: cover;
                  border-radius: 6px;
                }
                .productItemBoxRight {
                  margin-left: 10px;
                  flex: 1;
                  .productItemName {
                    display: flex;
                    align-items: flex-end;
                    .productName {
                      font-size: 15px;
                      color: #000;
                      font-weight: bold;
                    }
                    .companyName {
                      font-size: 12px;
                      color: #50515C;
                      transform: scale(.9);
                    }
                  }
                  .productItemOther {
                    padding-right: 20px;
                    margin-top: 3px;
                    display: flex;
                    // justify-content: space-between;
                    .productItemOtherItem {
                      margin-right: 32px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: space-between;
                      .productItemOtherItemValue {
                        font-size: 14px;
                        color: #FF3042;
                        font-weight: 600;
                      }
                      .productItemOtherItemLabel {
                        font-size: 12px;
                        color: #8C8C8C;
                        transform: scale(.9);
                      }
                    }
                  }
                }
                .checked {
                  width: 16px;
                  height: 16px;
                  position: absolute;
                  top: 50%;
                  right: 9px;
                  transform: translateY(-50%);
                  border: 1px solid #D9D9D9;
                  border-radius: 100%;
              
                  &.active {
                    border: none;
                    background: #FF3042;
              
                    &::before {
                      position: absolute;
                      top: 5px;
                      left: 3px;
                      width: 9px;
                      height: 6px;
                      content: "";
                      background-image: url("~@imgs/activity/check.png");
                      background-size: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .protocolBox {
        margin-top: 12px;
        padding: 18px 16px;
        border-radius: 8px;
        background-color: #fff;
        .protocolBoxContent {
          width: 311px;
          height: 160px;
          border-radius: 8px;
          // background: #F2F5FD;
          box-sizing: border-box;
          overflow-y: auto;
          .protocolBoxTitle {
            color: #262626;
            text-align: center;
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 12px;
          }
          p {
            color: #50515C;
            font-size: 12px;
            line-height: 24px;
          }
        }
      }
      .specialTips {
        margin-top: 12px;
        line-height: 20px;
        .specialTipsTag {
          display: inline-block;
          padding: 2px 8px;
          background: linear-gradient(320deg, #FF9052 0%, #FF1C2F 100%);
          border-radius: 4px 4px 4px 4px;
          font-size: 12px;
          color: #fff;
          margin-right: 5px;
          line-height: normal;
          transform: scale(.85);
        }
        .specialTipsContent {
          color: #50515C;
          font-size: 12px;
        }
      }
    }
  }
  .footerBox {
    padding: 15px 6px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #fff;
    box-sizing: border-box;
    .btnBox {
      padding: 0 16px;
      // margin-top: 16px;
      .btn {
        border: none;
        padding: 0;
        height: 48px;
        background: $linear-gradient;
        line-height: 50px;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .footerText {
      margin-bottom: 10px;
      font-size: 12px;
      color: #8C8C8C;
      text-align: center;
      line-height: 16px;
      display: flex;
      justify-content: center;

      .checkedIcon {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        margin-right: 8px;
        margin-bottom: .5px;
      }
  
      .checked {
        flex-shrink: 0;
        // display: inline-block;
        vertical-align: text-bottom;
        margin-right: 8px;
        margin-bottom: .5px;
        border: 1px solid #D9D9D9;
        border-radius: 100%;
        width: 14px;
        height: 14px;
    
        &.active {
          position: relative;
          border: none;
          background: #FF3042;
    
          &::before {
            position: absolute;
            top: 4px;
            left: 3px;
            width: 9px;
            height: 6px;
            content: "";
            background-image: url("~@imgs/activity/check.png");
            background-size: 100%;
          }
        }
      }
  
      .footerTextRed {
        color: #FF3042;
      }
    }
    .paddingBox {
      height: constant(safe-area-inset-bottom);
      height: env(safe-area-inset-bottom);
      background-color: #fff;
    }
  }
}

.protcolPopup {
  .popupTitle {
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
  }
  .protocolIdsBox {
    padding: 0 15px;
    .protocolIdItem {
      padding: 15px 0;
      border-bottom: 1px solid #DDD;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      .arrowBlack {
        width: 10px;
      }
    }
  }
}
