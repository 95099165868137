@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.skin6 {
  height: 100%;
  background-color: #f5f6f9;

  .header {
    padding: 30px 16px 0;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 210px;
    background-image: url("~@imgs/register/new-theme/head-bg.png");

    .logoBox {
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;

      @include flex-cc;

      .logo {
        margin-right: 10px;
        width: 42px;
        height: 42px;
        background-size: 100%;
        background-image: url("~@imgs/register/red-theme/logo-big.png");
      }

      .logoBoxRight {
        display: flex;
        flex-direction: column;
        .logoText {
          width: 80px;
          height: 21px
        }
        .youqianLogoText {
          //width: 54px;
          height: 21px
        }
        .logoText2 {
          margin-top: 4px;
          width: 79px;
          height: 11px
        }
        .logoText3 {
          margin-top: 1px;
          font-size: 12px;
          color: #fff;
          font-weight: 600;
          transform: scale(.86) translateX(-10px);
        }
      }
    }

    .headTitps {
      margin: 10px auto 20px;
      width: 243px;
      height: 22px;
      transform: translateX(-5px);
      img {
        width: 100%;
        height: 100%;
      }
    }

    .main {
      width: 343px;
      background: linear-gradient(180deg, #FBE5D7 0%, #FFFFFF 27.54%);
      border-radius: 8px;
      padding-bottom: 25px;
      padding: 24px 0;
      .limitTitle {
        font-size: 14px;
        color: #262626;
        text-align: center;
      }
      .limitNum {
        margin: 10px 0;
        font-size: 48px;
        line-height: 48px;
        color: #262626;
        text-align: center;
      }
      .limitTips {
        font-size: 12px;
        color: #8C8C8C;
        text-align: center;
      }
      .formBox {
        margin-top: 24px;
        box-sizing: border-box;
        padding: 0 12px;
        .phoneBox {
          height: 46px;
          background: #f4f5f7;
          border-radius: 8px;
          display: flex;
          align-items: center;
          margin: 9px auto 0;
          .label {
            width: 170px;
            text-align: center;
            font-size: 16px;
          }
          :global {
            .adm-input-element {
              font-size: 16px;
              // font-weight: 600;
              line-height: 30px;
              &::placeholder {
                font-size: 16px;
                line-height: 30px;
              }
            }
          }
        }
        .getCms {
          margin-top: 12px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: linear-gradient(180deg,#ffcfa0 1%,#e19459);
          border-radius: 6px;
          color: #FFFFFF;
          font-size: 16px;
        }
        .agreement {
          @include flex-cc;
          flex-wrap: wrap;
  
          .primary {
            color: #DE9960;
          }
      
          margin: 24px auto;
          font-size: 12px;
          color: #8C8C8C;
          line-height: 12px;
  
          &.animation {
            position: relative;
            animation: shake .1s;
          }
  
          @keyframes shake {
            0% {
              left: 8px;
            }
  
            50% {
              left: -8px;
            }
      
            100% {
              left: 0;
            }
          }
      
          .checked {
            margin-right: 6px;
            border: 1px solid #D9D9D9;
            border-radius: 100%;
            width: 14px;
            height: 14px;
            // background-color: #DE9960;
      
            &.active {
              position: relative;
              border: none;
              background: #DE9960;
      
              &::before {
                position: absolute;
                top: 4px;
                left: 2px;
                width: 9px;
                height: 6px;
                content: "";
                background-image: url("~@imgs/activity/check.png");
                background-size: 100%;
              }
            }
          }
        }
      }
    }
  }

  .footerBar {
    margin: 20px auto;
    padding: 24px 16px;
    width: 343px;
    background-color: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    .footerBarImg {
      width: 243px;
      height: 47px;
      display: block;
      margin: 0 auto;
    }
    .steps {
      margin-top: 24px;
      padding: 0 12px;
      display: flex;
      justify-content: space-between;
      .stepItem {
        .stepNum {
          width: 28px;
          height: 28px;
          margin: 0 auto;
          background-color: #ff5161;
          border-radius: 50%;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .stepName {
          margin-top: 8px;
          font-size: 12px;
          color: #262626;
        }
      }
      .splitLine {
        width: 48px;
        height: 1px;
        background-color: #EAEAEA;
        // border: 1px solid #EAEAEA;
        margin: 15px 8px;
      }
    }
  }

  .checkBox {
    width: 352px;
    height: 455px;
    margin: 11px auto ;
    background: #fff;
    border-radius: 8px;
    .title {
      width: 100%;
      height: 38px;
      background: linear-gradient(180deg, #FDF2E5 0%, #FBE4CE 100%);
      border-radius: 6px 6px 0px 0px;
      text-align: center;
      line-height: 38px;
      font-weight: 400;
      font-size: 12px;
      color: #DE9960;
    }
    h3 {
      font-weight: 600;
      font-size: 16px;
      color: #333;
      padding: 12px 16px 6px;
      span {
        font-size: 12px;
        color: #BB6C1D;
        font-weight: normal;
      }
    }
    .loanTermList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 16px;
      .loanTermItem {
        margin-top: 8px;
        width: 154px;
        height: 60px;
        padding-bottom: 6px;
        background-color: #f3f4f6;
        text-align: center;
        border-radius: 4px;
        font-size: 15px;
        box-sizing: border-box;
        p {
          margin-top: 6px;
          color: #8C8C8C;
          &.samllText {
            font-size: 12px;
          }
        }
        &.active {
          background-color: #fff9f4;
          border: 1Px solid #bb9674;
          p {
            color: #bb9674;
          }
        }
        &.is360Channel {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
        }
      }
    }
  }

  .tipBox {
    padding-bottom: 50px;
    .tip {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: $text-gray-color;
    }
  }

  .dialogOverlay {
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    &.showPopup {
      display: block;
    }
    .dialogMain {
      width: 320px;
      height: 180px;
      border-radius: 8px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px 0;
      box-sizing: border-box;
      .close {
        width: 100%;
        font-size: 20px;
        text-align: right;
        margin-right: 20px;
        color: rgb(214, 217, 223);
        padding-right: 20px;
        box-sizing: border-box;
      }
      .inputBox {
        width: 285px;
        height: 48px;
        border: 1px solid #e19459;
        margin: 10px auto;
        padding-left: 20px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        .code {
          width: 150px;
          text-align: center;
          color: #e19459;
        }
        :global {
          .adm-input-element {
            font-size: 15px;
            // font-weight: 600;
            line-height: 30px;
          }
        }
      }
      .getQuota {
        margin: 18px auto;
        width: 283px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(180deg,#ffcfa0 1%,#e19459);
        border-radius: 6px;
        color: #FFFFFF;
        font-size: 16px;
      }
    }
  }
}

:global {
  .adm-auto-center {
    .adm-auto-center-content {
      text-align: center !important;
    }
  }
}
